import { useFormikContext } from "formik";
import React from "react";

import { useDelay, useDimension } from "@common/Hooks";

import { StatusIcon } from "../assets/icons";
import { FilterValueType } from "../common/types";
import { updateChips } from "../common/utils";

import { CustomSelect } from "./custom-select";

type Props<T> = {
  field: keyof T;
  options: { value: string; label: string }[];
};

export const StatusFilter = <T,>({ field, options }: Props<T>) => {
  const { isDesktop } = useDimension();
  const { values, setValues, submitForm } =
    useFormikContext<FilterValueType<T>>();

  const debounceSubmitForm = useDelay(submitForm, 300);

  const handleSelect = (selectedValue: string) => {
    const fullValue = options.find((option) => option.value === selectedValue);
    const label = fullValue?.label || "";
    const chips = values.chips || [];

    const updatedChips = updateChips(chips, field as string, label);

    setValues({ ...values, [field]: selectedValue, chips: updatedChips });

    if (isDesktop) {
      debounceSubmitForm();
    }
  };

  return (
    <CustomSelect
      value={values[field] as unknown as string}
      label={isDesktop ? "" : "Статус"}
      data={options}
      icon={<StatusIcon />}
      placeholder="Выберите статус"
      onChange={handleSelect}
    />
  );
};
