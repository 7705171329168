import React from "react";

import { Grid } from "@components/Elements";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export const DesktopFilter = ({ children }: Props) => {
  return (
    <Grid
      columns="auto-fit"
      bgColor="neutral-0"
      minColumnWidth="280px"
      className="rounded-m p-2"
    >
      {children}
    </Grid>
  );
};
